.marcasContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.marcas{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1140px;
}

#id8 p{
    font-size: 18px;
}

.marca img{
    width: 300px;
}

@media (max-width: 910px){
    .marca img{
        width: 200px;
    }
}

@media (max-width: 610px){
    .marca img{
        width: 150px;
    }
}

@media (max-width: 460px){
    .marcas{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}