.clear-fix:after {
  display: block;
  clear: both;
  content: '';
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.card__collection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-family: var(--font-family);
  overflow: hidden;
  margin-top: 40px;
}

.card__collection .cards {
  width: 300px;
  height: fit-content;
  display: block;
  background-size: cover;
  float: left;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  background-position: center;
  margin: 10px;
}

.card__collection .cards--two {
  position: relative;
  backface-visibility: hidden;
}

.card__collection .cards--two p {
  position: absolute;
  top: 83%;
  left: -100%;
  text-transform: capitalize;
  color: #fff;
  font-size: 20px;
  max-width: 150px;
  z-index: 8;
  transition: all 0.6s ease;
}

.card__collection .cards--two:hover p {
  left: 8%;
}

#id2 p{
  top: 78%;
}

#id8 p{
  top: 75%;
}

.card__collection .cards--two:hover .cards--two__rect {
  top: 75%;
}

.card__collection .cards--two:hover .cards--two__rect:before {
  transform: translateY(15px);
}

.card__collection .cards--two__tri {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #fff;
  opacity: 0.9;
  position: absolute;
  display: block;
  top: 0;
  right: -100%;
  transition: all 0.3s ease-in-out;
}

.card__collection .cards--two__tri:before {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #57ccfd;
  position: absolute;
  content: '';
  display: block;
  top: -220px;
  right: -612px;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.card__collection .cards--two__rect {
  width: 750px;
  height: 200px;
  background: #fff;
  display: block;
  position: absolute;
  top: 175%;
  left: -78%;
  transform: rotate(30deg);
  z-index: 5;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}

.card__collection .cards--two__rect:before {
  content: '';
  display: block;
  width: 100%;
  position: relative;
  height: 100%;
  background: var(--color-secundary);
  transform: translateY(200px);
  z-index: 2;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.card__collection .cards--two ul {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 10px;
  z-index: 9;
}

.card__collection .cards--two ul li {
  display: inline-block;
  font-size: 16px;
  margin: 7px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  transform: translateY(100px);
}

.card__collection .cards--two ul li:nth-child(2) {
  transition-delay: 0.2s;
}

.card__collection .cards--two ul li:nth-child(3) {
  transition-delay: 0.3s;
}

.card__collection .cards--two ul li:nth-child(4) {
  transition-delay: 0.4s;
}

.contentCard{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid var(--color-secundary);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 225px;
}