.contacto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    color: #333;
}

.infoContacto{
    width: 100%;
    max-width: 1140px;
    padding: 40px 15px;
}

.infoContacto h1{
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--color-secundary);
}