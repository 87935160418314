ul,
li {
  list-style: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  transition: transform 0.5s ease-in-out;
}

li {
  display: inline-block;
  width: 100%;
}

img {
  width: 100%;
  display: block;
}

/* Main container */
.main-container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  position: relative; /* Añadir position relative para controlar elementos hijos */
  overflow: hidden; /* Asegura que no se vea contenido fuera del área del carrusel */
}

/* Slider container */
.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Dots carousel */
.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Espacio superior para los puntos */
  position: absolute;
  bottom: 10px; /* Alinea los puntos a la parte inferior de la imagen */
  width: 100%;
}

.dot-container-item {
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 50%;
  width: 1vw; /* Tamaño relativo al ancho de la ventana */
  height: 1vw; /* Tamaño relativo al ancho de la ventana */
  background-color: #fff; /* Fondo blanco para mayor visibilidad */
}

.dot-container-item.active {
  background-color: #333; /* Color oscuro para el punto activo */
}

/* Arrows */
.leftArrow,
.rightArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.5vw; /* Tamaño relativo al ancho de la ventana */
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  height: 3vw; /* Tamaño relativo al ancho de la ventana */
  width: 3vw; /* Tamaño relativo al ancho de la ventana */
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.leftArrow {
  left: 2vw; /* Ajuste a la izquierda con margen relativo */
}

.rightArrow {
  right: 2vw; /* Ajuste a la derecha con margen relativo */
}

.leftArrow:hover,
.rightArrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
}

.container-images {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.container-images ul {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.container-images ul li {
  min-width: 100%;
}
