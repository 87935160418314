.catalogo{
    width: 100%;
    height: 350px;
    background-image: url(../../assets/bg-image.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.catalogoContenido{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    color: white;
    font-family: var(--font-family);
}

.catalogoContenido h1{
    font-size: 36px;
    margin-bottom: 30px;
}

.btn{
    border: 1px solid #c50410;
    background-color: #e30513;
    padding: 10px 16px;
    font-size: 18px;
    
    border-radius: 6px;

    color: white;
    font-family: var(--font-family);
    transition: color 400ms, background-color 400ms, border-color 400ms;
}

.btn:hover{
    border-color: #98030d;
    background-color: #b1040f;
}