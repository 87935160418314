.infoQuienesSomosContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.infoQuienesSomos p{
    font-family: var(--font-family);
    color: #333;
}

.infoQuienesSomos{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1140px;
    margin-top: 40px;
}

.infoQuienesSomos img{
    width: 100%;
    height: auto;
    max-width: 650px;
}

.textos{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.textos div{
    max-width: 400px;
    font-size: 20px;
}

@media (max-width: 1160px){
    .infoQuienesSomos img{
        max-width: 500px;
    }
}
@media (max-width: 960px){
    .infoQuienesSomos{
        max-width: 700px;
        flex-direction: column-reverse;
    }
    .textos{
        margin-top: 30px;
        width: 100%;
        max-width: 800px;
        flex-direction: row;
        justify-content: space-between;
    }
    .textos div{
        max-width: 300px;
        font-size: 17px;
    }
}

@media (max-width: 550px){
    .textos{
        text-align: center;
        align-items: center;
        flex-direction: column;
    }
}
