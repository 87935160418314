.cardsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.container{
    max-width: 1140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}