.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 0.7rem 2rem;
  border-bottom: 6px solid #e30513;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1170px;
  width: 100%;
}

.navbar-links_logo img {
  margin-top: 5px;
  width: 100%;
  max-width: 320px;
}

.navbar-links_container {
  display: flex;
  flex-direction: row;
}

.navbar-links_container p,
.navbar-menu_container {
  color: black;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 1rem;
  cursor: pointer;
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: white;
  border: 2px solid #e30513;
  padding: 1rem 1.5rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 220px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.navbar-links_container a:hover {
  color: #e30513;
}

.navbar-menu_container-links a:hover {
  color: #e30513;
}

.navbar-menu_container p {
  margin: 1rem 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e30513;
  width: 100%;
  text-align: right;
  transition: color 0.3s;
}

.navbar-menu_container p:last-child {
  border-bottom: 1px solid #e30513;
}

@media screen and (max-width: 1050px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 2rem;
  }
  .navbar-menu_container {
    top: 20px;
  }
}
