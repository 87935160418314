* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  
 /*body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }*/

  a {
    color: unset;
    text-decoration: none;
  }