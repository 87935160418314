.descargasQuienesSomos{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 50px;
    max-width: 900px;
}

.descarga h1{
    font-size: 20px;
}

.descarga a img{
    width: 100%;
    max-width: 180px;
}

#img2{
    max-width: 240px;
}
@media (max-width: 550px){
    .descarga a img{
        max-width: 100px!important;
    }
}